import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

const defaultState = {
  loading: false,
  sidebar: false
}

const app = (state = defaultState, { type, payload }) => {
  switch (type) {
    case '@@redux-form/START_SUBMIT':
      return {
        ...state,
        loading: true
      }
    case '@@redux-form/STOP_SUBMIT':
      return {
        ...state,
        loading: false
      }
    case 'APP_LOADING':
      return {
        ...state,
        loading: payload
      }
    case 'APP_SIDEBAR':
      return {
        ...state,
        sidebar: payload
      }
    default:
      return state
  }
}

export default combineReducers({
  // ...your other reducers here
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  form,
  app
})
