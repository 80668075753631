"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "withTheme", {
  enumerable: true,
  get: function get() {
    return _withTheme.default;
  }
});
exports.dark = exports.theme = void 0;

var _amber = _interopRequireDefault(require("@material-ui/core/colors/amber"));

var _withTheme = _interopRequireDefault(require("./withTheme"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var theme = {
  palette: {
    primary: {
      main: '#203765'
    },
    secondary: {
      main: _amber.default[500]
    }
  },
  typography: {
    useNextVariants: true
  }
};
exports.theme = theme;

var dark = _objectSpread({}, theme, {
  palette: {
    type: 'dark'
  }
});

exports.dark = dark;