"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Snackbar = _interopRequireDefault(require("@material-ui/core/Snackbar"));

var _styles = require("@material-ui/core/styles");

var _ui = require("@ftrustee/ui");

var _Card = _interopRequireDefault(require("@material-ui/core/Card"));

var _CardActions = _interopRequireDefault(require("@material-ui/core/CardActions"));

var _CardContent = _interopRequireDefault(require("@material-ui/core/CardContent"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var SigninPage = function SigninPage(_ref) {
  var classes = _ref.classes;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      error = _useState2[0],
      setError = _useState2[1];

  var firebase = (0, _react.useContext)(_ui.FirebaseContext);
  return _react.default.createElement("div", {
    className: classes.appFrame
  }, _react.default.createElement(_Card.default, {
    style: {
      maxWidth: 400,
      margin: 'auto'
    }
  }, _react.default.createElement("form", {
    autoComplete: "off",
    onSubmit: function onSubmit(event) {
      event.preventDefault();

      if (event.target.reportValidity()) {
        /* global FormData */
        var formData = new FormData(event.target);
        firebase.auth().signInWithEmailAndPassword(formData.get('email'), formData.get('password')).catch(function (error) {
          return setError(error);
        });
      }
    }
  }, _react.default.createElement(_CardContent.default, null, _react.default.createElement(_TextField.default, {
    required: true,
    fullWidth: true,
    type: "email",
    label: "Email",
    name: "email"
  }), _react.default.createElement(_TextField.default, {
    required: true,
    fullWidth: true,
    type: "password",
    label: "Senha",
    name: "password"
  })), _react.default.createElement(_CardActions.default, null, _react.default.createElement(_Button.default, {
    fullWidth: true,
    variant: "contained",
    color: "primary",
    type: "submit"
  }, "Entrar")))), _react.default.createElement(_Snackbar.default, {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    open: !!error,
    autoHideDuration: 6000,
    onClose: function onClose() {
      return setError(false);
    },
    message: !!error && error.message
  }));
};

var styles = function styles(theme) {
  return {
    appFrame: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      minHeight: '100vh' // Makes the frame full height.

    },
    card: {
      minWidth: 275,
      margin: 'auto'
    }
  };
};

var _default = (0, _styles.withStyles)(styles, {
  withTheme: true
})(SigninPage);

exports.default = _default;