"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reduxForm = require("redux-form");

var _Paper = _interopRequireDefault(require("@material-ui/core/Paper"));

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _Save = _interopRequireDefault(require("@material-ui/icons/Save"));

var _Delete = _interopRequireDefault(require("@material-ui/icons/Delete"));

var _styles = require("@material-ui/core/styles");

var _CircularProgress = _interopRequireDefault(require("@material-ui/core/CircularProgress"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var FormComponent = function FormComponent(_ref) {
  var loading = _ref.loading,
      children = _ref.children,
      classes = _ref.classes,
      initialized = _ref.initialized,
      pristine = _ref.pristine,
      submitting = _ref.submitting,
      handleSubmit = _ref.handleSubmit,
      onCancel = _ref.onCancel,
      onDelete = _ref.onDelete;
  return !loading ? _react.default.createElement(_Paper.default, {
    classes: {
      root: classes.paper
    }
  }, _react.default.createElement(_reduxForm.Form, {
    noValidate: true,
    autoComplete: "off",
    onSubmit: handleSubmit
  }, _react.default.createElement(_Grid.default, {
    container: true,
    spacing: 16
  }, children), _react.default.createElement(_Grid.default, {
    container: true,
    spacing: 16,
    direction: "row",
    alignItems: "center",
    justify: "flex-end"
  }, _react.default.createElement(_Grid.default, {
    item: true
  }, _react.default.createElement(_Button.default, {
    color: "default",
    onClick: onCancel
  }, "Cancelar")), initialized && _react.default.createElement(_Grid.default, {
    item: true
  }, _react.default.createElement(_Button.default, {
    variant: "contained",
    color: "default",
    onClick: onDelete,
    disabled: submitting
  }, _react.default.createElement(_Delete.default, {
    className: classes.leftIcon,
    fontSize: "small"
  }), "Apagar")), _react.default.createElement(_Grid.default, {
    item: true
  }, _react.default.createElement(_Button.default, {
    variant: "contained",
    color: "primary",
    type: "submit",
    disabled: pristine || submitting
  }, _react.default.createElement(_Save.default, {
    className: classes.leftIcon,
    fontSize: "small"
  }), "Salvar"))))) : _react.default.createElement("div", {
    style: {
      display: 'flex',
      justifyContent: 'center'
    }
  }, _react.default.createElement(_CircularProgress.default, null));
};

FormComponent.propTypes = {
  classes: _propTypes.default.object.isRequired,
  children: _propTypes.default.node.isRequired
};

var styles = function styles(theme) {
  return {
    paper: {
      padding: theme.spacing.unit * 2,
      maxWidth: 1485
    },
    leftIcon: {
      marginRight: theme.spacing.unit
    }
  };
};

var _default = (0, _styles.withStyles)(styles)(FormComponent);

exports.default = _default;