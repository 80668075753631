import React from 'react'
import { FieldArray } from 'redux-form'
import { pure, onlyUpdateForKeys } from 'recompose'
import Button from '@material-ui/core/Button'

import RegisterItem from './RegisterItem'

const Field = onlyUpdateForKeys(['member', 'index'])(
  ({ member, index, fields, options }) => {
    // console.log('Field:', member, index, fields, options)

    const AddAction = () => (
      <Button
        fullWidth
        variant="outlined"
        size="small"
        color="primary"
        aria-label="adicionar"
        onClick={() => fields.push()}>
        Adicionar Registro
      </Button>
    )
    const RemoveAction = () => (
      <Button
        fullWidth
        variant="outlined"
        size="small"
        aria-label="adicionar"
        onClick={() => fields.remove(index)}>
        Remover Registro
      </Button>
    )
    return (
      <RegisterItem key={index} name={member} options={options}>
        {index > 0 ? <RemoveAction /> : <AddAction />}
      </RegisterItem>
    )
  }
)

const renderMembers = onlyUpdateForKeys(['options', 'fields'])(
  ({ fields, options, ...props }) => {
    // console.log('renderMembers:', fields, props)
    fields.length === 0 && fields.push()
    return (
      <React.Fragment>
        {fields.map((member, index, fields) => {
          return <Field key={index} {...{ member, index, fields, options }} />
        })}
      </React.Fragment>
    )
  }
)

const RegisterList = props => {
  // console.log('RegisterList:', props)
  return <FieldArray {...props} component={renderMembers} />
}

export default pure(RegisterList)
