"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reduxForm = require("redux-form");

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _Icon = _interopRequireDefault(require("@material-ui/core/Icon"));

var _IconButton = _interopRequireDefault(require("@material-ui/core/IconButton"));

var _ = require("./");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var renderAddress = function renderAddress(_ref) {
  var fields = _ref.fields,
      _ref$meta = _ref.meta,
      error = _ref$meta.error,
      submitFailed = _ref$meta.submitFailed;
  var options = [{
    value: 'main',
    label: 'Principal'
  }, {
    value: 'accounting',
    label: 'Cobrança'
  }, {
    value: 'other',
    label: 'Outro'
  }];
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_.TextField, {
    name: "".concat(fields.name, "[0].lines[0]"),
    label: "Endere\xE7o",
    xs: 12,
    sm: 6
  }), _react.default.createElement(_.TextField, {
    sm: true,
    xs: 12,
    name: "".concat(fields.name, "[0].city"),
    label: "Cidate"
  }), _react.default.createElement(_.TextField, {
    name: "".concat(fields.name, "[0].state"),
    label: "Estado",
    sm: 1,
    xs: 4,
    inputProps: {
      maxLength: 2
    }
  }), _react.default.createElement(_.TextField, {
    name: "".concat(fields.name, "[0].country"),
    label: "Pa\xEDs"
  }), _react.default.createElement(_.TextField, {
    name: "".concat(fields.name, "[0].lines[1]"),
    label: "Complemento",
    xs: 12,
    sm: 6
  }), _react.default.createElement(_.TextField, {
    name: "".concat(fields.name, "[0].postcode"),
    label: "CEP",
    type: "cep",
    sm: 2,
    xs: 5
  }), _react.default.createElement(_.SelectField, {
    xs: true,
    name: "".concat(fields.name, "[0].type"),
    label: "Tipo",
    options: options
  }), _react.default.createElement(_Grid.default, {
    item: true,
    xs: 2
  }, _react.default.createElement(_IconButton.default, {
    color: "secondary",
    "aria-label": "adicionar",
    style: {
      marginTop: 10
    },
    onClick: function onClick() {
      return fields.push();
    }
  }, _react.default.createElement(_Icon.default, null, "add_circle")))), fields.map(function (member, index) {
    return index > 0 && _react.default.createElement(_react.default.Fragment, {
      key: index
    }, _react.default.createElement(_.TextField, {
      name: "".concat(member, ".lines[0]"),
      label: "Endere\xE7o",
      xs: 12,
      sm: 6
    }), _react.default.createElement(_.TextField, {
      sm: true,
      xs: 12,
      name: "".concat(member, ".city"),
      label: "Cidate"
    }), _react.default.createElement(_.TextField, {
      name: "".concat(member, ".state"),
      label: "Estado",
      sm: 1,
      xs: 4,
      inputProps: {
        maxLength: 2
      }
    }), _react.default.createElement(_.TextField, {
      name: "".concat(member, ".country"),
      label: "Pa\xEDs"
    }), _react.default.createElement(_.TextField, {
      name: "".concat(member, ".lines[1]"),
      label: "Complemento",
      xs: 12,
      sm: 6
    }), _react.default.createElement(_.TextField, {
      name: "".concat(member, ".postcode"),
      label: "CEP",
      type: "cep",
      sm: 2,
      xs: 5
    }), _react.default.createElement(_.SelectField, {
      xs: true,
      name: "".concat(member, ".type"),
      label: "Tipo",
      options: options
    }), _react.default.createElement(_Grid.default, {
      item: true,
      xs: 2
    }, _react.default.createElement(_IconButton.default, {
      color: "secondary",
      "aria-label": "adicionar",
      style: {
        marginTop: 10
      },
      onClick: function onClick() {
        return fields.remove(index);
      }
    }, _react.default.createElement(_Icon.default, null, "remove_circle"))));
  }));
};

var AddressList = function AddressList() {
  return _react.default.createElement(_reduxForm.FieldArray, {
    name: "address",
    component: renderAddress
  });
};

var _default = AddressList;
exports.default = _default;