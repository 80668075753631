"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reduxForm = require("redux-form");

var _Icon = _interopRequireDefault(require("@material-ui/core/Icon"));

var _IconButton = _interopRequireDefault(require("@material-ui/core/IconButton"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var handleChange = function handleChange(handler) {
  return function (_ref) {
    var files = _ref.target.files;
    return handler(files.length ? {
      file: files[0],
      name: files[0].name
    } : {});
  };
};

var renderField = function renderField(_ref2) {
  var _ref2$input = _ref2.input,
      onChange = _ref2$input.onChange,
      onBlur = _ref2$input.onBlur,
      omitValue = _ref2$input.value,
      inputProps = _objectWithoutProperties(_ref2$input, ["onChange", "onBlur", "value"]),
      omitMeta = _ref2.meta,
      props = _objectWithoutProperties(_ref2, ["input", "meta"]);

  return _react.default.createElement("label", null, _react.default.createElement("input", _extends({
    id: inputProps.name,
    type: "file",
    accept: "application/pdf",
    style: {
      display: 'none'
    },
    onChange: handleChange(onChange),
    onBlur: handleChange(onBlur)
  }, inputProps, props)), _react.default.createElement(_IconButton.default, {
    color: "secondary",
    component: "span"
  }, _react.default.createElement(_Icon.default, null, "cloud_upload")));
};

var FileInput = function FileInput(props) {
  return _react.default.createElement(_reduxForm.Field, _extends({}, props, {
    component: renderField
  }));
};

var _default = FileInput;
exports.default = _default;