"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reduxForm = require("redux-form");

var _reactNumberFormat = _interopRequireDefault(require("react-number-format"));

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var isRequired = function isRequired(value) {
  return value ? undefined : 'Obrigatório';
};

var renderField = function renderField(_ref) {
  var xs = _ref.xs,
      sm = _ref.sm,
      md = _ref.md,
      lg = _ref.lg,
      xl = _ref.xl,
      _ref$input = _ref.input,
      onChange = _ref$input.onChange,
      _onBlur = _ref$input.onBlur,
      input = _objectWithoutProperties(_ref$input, ["onChange", "onBlur"]),
      _ref$meta = _ref.meta,
      touched = _ref$meta.touched,
      error = _ref$meta.error,
      helperText = _ref.helperText,
      props = _objectWithoutProperties(_ref, ["xs", "sm", "md", "lg", "xl", "input", "meta", "helperText"]);

  return _react.default.createElement(_Grid.default, _extends({
    item: true
  }, {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    xl: xl
  }), _react.default.createElement(_reactNumberFormat.default, _extends({
    fullWidth: true,
    thousandSeparator: ".",
    decimalSeparator: ",",
    customInput: _TextField.default
  }, input, props, {
    onBlur: function onBlur(e) {
      return _onBlur(undefined);
    },
    onValueChange: function onValueChange(values) {
      return onChange(values.floatValue || '');
    },
    error: touched && !!error,
    helperText: touched && !!error && error || helperText
  })));
};

var NumberField = function NumberField(props) {
  return _react.default.createElement(_reduxForm.Field, _extends({
    validate: props.required ? [isRequired] : []
  }, props, {
    component: renderField
  }));
};

var _default = NumberField;
exports.default = _default;