"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _recompose = require("recompose");

var _LinearProgress = _interopRequireDefault(require("@material-ui/core/LinearProgress"));

var _styles = require("@material-ui/core/styles");

var _Header = _interopRequireDefault(require("./Header"));

var _Sidebar = _interopRequireDefault(require("./Sidebar"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    container: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      backgroundColor: theme.palette.background.default
    },
    main: {
      flex: 1
    }
  };
};

var Page = function Page(_ref) {
  var loading = _ref.loading,
      classes = _ref.classes,
      children = _ref.children,
      title = _ref.title,
      _handleDrawer = _ref.handleDrawer,
      open = _ref.open,
      menu = _ref.menu;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_LinearProgress.default, {
    variant: loading ? 'indeterminate' : 'determinate',
    value: 100
  }), _react.default.createElement(_Header.default, {
    handleDrawer: function handleDrawer() {
      return _handleDrawer(!open);
    },
    title: title
  }), _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement(_Sidebar.default, {
    open: open,
    menu: menu
  }), _react.default.createElement("main", {
    className: classes.main
  }, children)));
};

Page.propTypes = {
  classes: _propTypes.default.object.isRequired,
  theme: _propTypes.default.object.isRequired
};

var _default = (0, _recompose.compose)((0, _styles.withStyles)(styles, {
  withTheme: true
}))(Page);

exports.default = _default;