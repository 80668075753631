import React from 'react'
import { FormSection, reduxForm } from 'redux-form'
import { compose } from 'recompose'

import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'

import { TextField, SelectField, DateField } from '@ftrustee/ui'

import RegisterList from './RegisterList'

const FormView = ({
  documentId,
  classes,
  options,
  handleSubmit,
  pristine,
  reset,
  submitting,
  error,
  notify,
  toggleNotification,
  onCancel,
  onDelete
}) => (
  <form
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    className={classes.form}>
    <Card>
      <CardContent>
        <Grid container spacing={16}>
          <SelectField
            sm={4}
            required
            name="inventory"
            label="Inventário"
            options={options.inventories}
          />
          <TextField required sm name="title" label="Título do Documento" />
          <Grid item xs={12}>
            <RegisterList name="registers" options={options.registers} />
          </Grid>
          <Grid item xs>
            <Grid container spacing={16}>
              <DateField sm={3} name="issueDate" label="Data do Documento" />
              <TextField
                sm
                multiline
                rowsMax="4"
                name="notes"
                label="Anotações"
              />
              <Grid item sm={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="caption" color="primary">
                      ACOMPANHAMENTO
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormSection
                      container
                      spacing={16}
                      component={Grid}
                      name="tracking">
                      <DateField
                        sm={3}
                        name="request"
                        label="DT. SOLICITAÇÃO"
                      />
                      <DateField sm={3} name="deadline" label="DT. LIMITE" />
                      <DateField
                        sm={3}
                        name="expiration"
                        label="DT. EXPIRAÇÃO"
                      />
                      <DateField sm={3} name="alert" label="ALERTA" />
                    </FormSection>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          size="small"
          onClick={() => {
            reset()
            onCancel && onCancel()
          }}>
          Cancel
        </Button>
        {!!onDelete && (
          <Button
            size="small"
            variant="contained"
            onClick={() => onDelete(documentId)}>
            Apagar
          </Button>
        )}
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          disabled={pristine || submitting}>
          Save
        </Button>
      </CardActions>
    </Card>
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={notify}
      autoHideDuration={6000}
      onClose={() => toggleNotification(false)}
      message={error}
    />
  </form>
)

const styles = theme => ({
  form: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'document',
    enableReinitialize: true,
    forceUnregisterOnUnmount: true
  })
)(FormView)
