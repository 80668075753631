import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import reducers from './reducers'

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunk)
    // other store enhancers if any
  )
)
// Preconfigured HOC
const withRedux = BaseComponent => props => (
  <Provider store={store}>
    <BaseComponent {...props} />
  </Provider>
)

export default withRedux
