"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reduxForm = require("redux-form");

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _Icon = _interopRequireDefault(require("@material-ui/core/Icon"));

var _IconButton = _interopRequireDefault(require("@material-ui/core/IconButton"));

var _ = require("./");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var renderPhones = function renderPhones(_ref) {
  var fields = _ref.fields,
      _ref$meta = _ref.meta,
      error = _ref$meta.error,
      submitFailed = _ref$meta.submitFailed;
  var options = [{
    value: 'main',
    label: 'Principal'
  }, {
    value: 'accounting',
    label: 'Cobrança'
  }, {
    value: 'fax',
    label: 'FAX'
  }, {
    value: 'other',
    label: 'Outro'
  }];
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Grid.default, {
    item: true,
    xs: 12,
    sm: 6
  }, _react.default.createElement(_Grid.default, {
    container: true,
    spacing: 16
  }, _react.default.createElement(_.TextField, {
    name: "".concat(fields.name, "[0].value"),
    label: "Telefone",
    type: "phone",
    xs: 6
  }), _react.default.createElement(_.SelectField, {
    name: "".concat(fields.name, "[0].type"),
    label: "Tipo",
    options: options,
    xs: 4
  }), _react.default.createElement(_Grid.default, {
    item: true,
    xs: 2
  }, _react.default.createElement(_IconButton.default, {
    color: "secondary",
    "aria-label": "adicionar",
    style: {
      marginTop: 10
    },
    onClick: function onClick() {
      return fields.push();
    }
  }, _react.default.createElement(_Icon.default, null, "add_circle"))))), fields.map(function (member, index) {
    return index > 0 && _react.default.createElement(_Grid.default, {
      item: true,
      xs: 12,
      sm: 6,
      key: index
    }, _react.default.createElement(_Grid.default, {
      container: true,
      spacing: 16
    }, _react.default.createElement(_.TextField, {
      name: "".concat(member, ".value"),
      label: "Telefone",
      type: "phone",
      xs: 6
    }), _react.default.createElement(_.SelectField, {
      name: "".concat(member, ".type"),
      label: "Tipo",
      options: options,
      xs: 4
    }), _react.default.createElement(_Grid.default, {
      item: true,
      xs: 2
    }, _react.default.createElement(_IconButton.default, {
      color: "secondary",
      "aria-label": "adicionar",
      style: {
        marginTop: 10
      },
      onClick: function onClick() {
        return fields.remove(index);
      }
    }, _react.default.createElement(_Icon.default, null, "remove_circle")))));
  }));
};

var PhoneList = function PhoneList() {
  return _react.default.createElement(_reduxForm.FieldArray, {
    name: "phones",
    component: renderPhones
  });
};

var _default = PhoneList;
exports.default = _default;