import React from 'react'
import { Switch, Route } from 'react-router-dom'

import GridContainer from './GridContainer'
import FormContainer from './FormContainer'

const SecurityPage = ({ match, title }) => {
  return (
    <Switch>
      <Route path={`${match.path}/new`} component={FormContainer} />
      <Route path={`${match.path}/:id`} component={FormContainer} />
      <Route path={`${match.path}/`} component={GridContainer} />
    </Switch>
  )
}

export default SecurityPage
