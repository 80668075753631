import React from 'react'
import ReactDOM from 'react-dom'
import Main from './main'
import registerServiceWorker from './registerServiceWorker'
import moment from 'moment'
import 'moment/locale/pt'
import 'typeface-roboto'
import 'material-design-icons/iconfont/material-icons.css'
import './index.css'

import { compose } from 'recompose'
import withApollo from './withApollo'
import withRedux from './withRedux'
import { withTheme } from '@ftrustee/ui'

const Root = compose(
  withRedux,
  withApollo,
  withTheme
)(Main)

moment.locale('pt-BR')
ReactDOM.render(<Root />, document.getElementById('root'))
registerServiceWorker()
