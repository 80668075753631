import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { logo } from '@ftrustee/ui'
import './App.css'

const styles = theme => ({
  title: {
    fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif'
  }
})

const Home = ({ classes, match }) => {
  console.log(match)
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Typography
          variant="h5"
          color="inherit"
          classes={{ root: classes.title }}>
          Controle de Documentação
        </Typography>
      </header>
      <p className="App-intro" />
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(Home)
