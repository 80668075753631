"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _recompose = require("recompose");

var _classnames = _interopRequireDefault(require("classnames"));

var _reduxForm = require("redux-form");

var _reactSelect = _interopRequireDefault(require("react-select"));

var _Async = _interopRequireDefault(require("react-select/lib/Async"));

var _NoSsr = _interopRequireDefault(require("@material-ui/core/NoSsr"));

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _styles = require("@material-ui/core/styles");

var _colorManipulator = require("@material-ui/core/styles/colorManipulator");

var _Paper = _interopRequireDefault(require("@material-ui/core/Paper"));

var _Chip = _interopRequireDefault(require("@material-ui/core/Chip"));

var _MenuItem = _interopRequireDefault(require("@material-ui/core/MenuItem"));

var _Cancel = _interopRequireDefault(require("@material-ui/icons/Cancel"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));

var _lodash = require("lodash");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles(theme) {
  return {
    root: {
      flexGrow: 1,
      height: 250
    },
    input: {
      display: 'flex',
      padding: 0
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden'
    },
    chip: {
      margin: "".concat(theme.spacing.unit / 2, "px ").concat(theme.spacing.unit / 4, "px")
    },
    chipFocused: {
      backgroundColor: (0, _colorManipulator.emphasize)(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
    },
    noOptionsMessage: {
      padding: "".concat(theme.spacing.unit, "px ").concat(theme.spacing.unit * 2, "px")
    },
    singleValue: {
      fontSize: 16
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0
    },
    divider: {
      height: theme.spacing.unit * 2
    }
  };
};

function NoOptionsMessage(props) {
  return _react.default.createElement(_Typography.default, _extends({
    color: "textSecondary",
    className: props.selectProps.classes.noOptionsMessage
  }, props.innerProps), props.children);
}

function inputComponent(_ref) {
  var inputRef = _ref.inputRef,
      props = _objectWithoutProperties(_ref, ["inputRef"]);

  return _react.default.createElement("div", _extends({
    ref: inputRef
  }, props));
}

function Control(props) {
  return _react.default.createElement(_TextField.default, _extends({
    fullWidth: true,
    InputProps: {
      inputComponent: inputComponent,
      inputProps: _objectSpread({
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children
      }, props.innerProps)
    }
  }, props.selectProps.textFieldProps));
}

function Option(props) {
  return _react.default.createElement(_MenuItem.default, _extends({
    buttonRef: props.innerRef,
    selected: props.isFocused,
    component: "div",
    style: {
      fontWeight: props.isSelected ? 500 : 400
    }
  }, props.innerProps), props.children);
}

function Placeholder(props) {
  return _react.default.createElement(_Typography.default, _extends({
    color: "textSecondary",
    className: props.selectProps.classes.placeholder
  }, props.innerProps), props.children);
}

function SingleValue(props) {
  return _react.default.createElement(_Typography.default, _extends({
    className: props.selectProps.classes.singleValue
  }, props.innerProps), props.children);
}

function ValueContainer(props) {
  return _react.default.createElement("div", {
    className: props.selectProps.classes.valueContainer
  }, props.children);
}

function MultiValue(props) {
  return _react.default.createElement(_Chip.default, {
    tabIndex: -1,
    label: props.children,
    className: (0, _classnames.default)(props.selectProps.classes.chip, _defineProperty({}, props.selectProps.classes.chipFocused, props.isFocused)),
    onDelete: props.removeProps.onClick,
    deleteIcon: _react.default.createElement(_Cancel.default, props.removeProps)
  });
}

function Menu(props) {
  return _react.default.createElement(_Paper.default, _extends({
    square: true,
    className: props.selectProps.classes.paper
  }, props.innerProps), props.children);
}

var components = {
  Control: Control,
  Menu: Menu,
  MultiValue: MultiValue,
  NoOptionsMessage: NoOptionsMessage,
  Option: Option,
  Placeholder: Placeholder,
  SingleValue: SingleValue,
  ValueContainer: ValueContainer
};

var isRequired = function isRequired(value) {
  return value ? undefined : 'Obrigatório';
};

var renderTextField = function renderTextField(_ref2) {
  var xs = _ref2.xs,
      sm = _ref2.sm,
      md = _ref2.md,
      lg = _ref2.lg,
      xl = _ref2.xl,
      _ref2$input = _ref2.input,
      name = _ref2$input.name,
      _onBlur = _ref2$input.onBlur,
      _onChange = _ref2$input.onChange,
      input = _objectWithoutProperties(_ref2$input, ["name", "onBlur", "onChange"]),
      _ref2$meta = _ref2.meta,
      initial = _ref2$meta.initial,
      active = _ref2$meta.active,
      touched = _ref2$meta.touched,
      error = _ref2$meta.error,
      meta = _objectWithoutProperties(_ref2$meta, ["initial", "active", "touched", "error"]),
      label = _ref2.label,
      helperText = _ref2.helperText,
      classes = _ref2.classes,
      theme = _ref2.theme,
      options = _ref2.options,
      isAsync = _ref2.isAsync,
      props = _objectWithoutProperties(_ref2, ["xs", "sm", "md", "lg", "xl", "input", "meta", "label", "helperText", "classes", "theme", "options", "isAsync"]);

  var selectStyles = {
    input: function input(base) {
      return _objectSpread({}, base, {
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      });
    }
  };

  var loadOptions = function loadOptions(inputValue, callback) {
    return inputValue.length > 2 ? callback((0, _lodash.filter)(options, function (_ref3) {
      var label = _ref3.label;
      return label.toLowerCase().includes(inputValue.toLowerCase());
    })) : callback([]);
  }; // console.log(input, props)


  var SelectField = isAsync ? _Async.default : _reactSelect.default;
  return _react.default.createElement(_Grid.default, _extends({
    item: true
  }, {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    xl: xl
  }), _react.default.createElement(_NoSsr.default, null, _react.default.createElement(SelectField, _extends({}, props, {
    isClearable: true,
    isSearchable: true,
    name: name,
    placeholder: "",
    classes: classes,
    styles: selectStyles,
    components: components,
    options: options,
    loadOptions: loadOptions,
    value: options && (!(0, _lodash.isArray)(input.value) ? options.filter(function (_ref4) {
      var value = _ref4.value;
      return value === input.value;
    }) : options.filter(function (_ref5) {
      var value = _ref5.value;
      return input.value.includes(value);
    })),
    textFieldProps: {
      label: label,
      error: touched && !!error,
      helperText: touched && !!error && error || helperText,
      InputLabelProps: {
        shrink: !!input.value || active
      }
    },
    onBlur: function onBlur(e) {
      return _onBlur(undefined);
    },
    onChange: function onChange(opt) {
      opt && (opt.value ? _onChange(opt.value) : _onChange(opt.map(function (_ref6) {
        var value = _ref6.value;
        return value;
      })));
    }
  }))));
};

var AutocompleteField = function AutocompleteField(props) {
  return _react.default.createElement(_reduxForm.Field, _extends({}, props, {
    component: (0, _recompose.pure)((0, _styles.withStyles)(styles, {
      withTheme: true
    })(renderTextField)),
    validate: props.required ? [isRequired] : []
  }));
};

var _default = (0, _recompose.pure)(AutocompleteField);

exports.default = _default;