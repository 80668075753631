"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = withTheme;

var _react = _interopRequireDefault(require("react"));

var _jss = require("jss");

var _JssProvider = _interopRequireDefault(require("react-jss/lib/JssProvider"));

var _styles = require("@material-ui/core/styles");

var _CssBaseline = _interopRequireDefault(require("@material-ui/core/CssBaseline"));

var _moment = _interopRequireDefault(require("moment"));

require("moment/locale/pt-br");

var _moment2 = _interopRequireDefault(require("@date-io/moment"));

var _MuiPickersUtilsProvider = _interopRequireDefault(require("material-ui-pickers/MuiPickersUtilsProvider"));

var _ = require("./");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

_moment.default.locale('pt-BR'); // A theme with custom primary and secondary color.
// It's optional.
// Create a JSS instance with the default preset of plugins.
// It's optional.


var jss = (0, _jss.create)((0, _styles.jssPreset)()); // The standard class name generator.
// It's optional.

var generateClassName = (0, _styles.createGenerateClassName)();

function withTheme(Component) {
  function WithTheme(props) {
    // JssProvider allows customizing the JSS styling solution.
    return _react.default.createElement(_JssProvider.default, {
      jss: jss,
      generateClassName: generateClassName
    }, _react.default.createElement(_styles.MuiThemeProvider, {
      theme: (0, _styles.createMuiTheme)(_.theme)
    }, _react.default.createElement(_MuiPickersUtilsProvider.default, {
      utils: _moment2.default,
      moment: _moment.default,
      locale: "pt-BR"
    }, _react.default.createElement(_CssBaseline.default, null), _react.default.createElement(Component, props))));
  }

  return WithTheme;
}