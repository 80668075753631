"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _AppBar = _interopRequireDefault(require("@material-ui/core/AppBar"));

var _Toolbar = _interopRequireDefault(require("@material-ui/core/Toolbar"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

var _Menu = _interopRequireDefault(require("@material-ui/icons/Menu"));

var _IconButton = _interopRequireDefault(require("@material-ui/core/IconButton"));

var _recompose = require("recompose");

var _reactHelmet = require("react-helmet");

var _styles = require("@material-ui/core/styles");

var _reactRouter = require("react-router");

var _ftrustee = _interopRequireDefault(require("../../assets/ftrustee.png"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var styles = function styles(theme) {
  return {
    flex: {
      flex: 'auto'
    },
    menuButton: {
      marginLeft: theme.spacing.unit * 1,
      marginRight: theme.spacing.unit * 2
    },
    logo: {
      fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '2rem'
    }
  };
};

var Title = (0, _recompose.compose)((0, _recompose.withState)('title', 'setTitle', function (_ref) {
  var title = _ref.title;
  return title;
}), _recompose.pure)(function (_ref2) {
  var title = _ref2.title,
      setTitle = _ref2.setTitle;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactHelmet.Helmet, {
    onChangeClientState: function onChangeClientState(_ref3) {
      var title = _ref3.title;
      console.log(title);
      setTitle(title);
    }
  }), _react.default.createElement(_Typography.default, {
    variant: "h6",
    color: "inherit"
  }, title));
});

var Header = function Header(_ref4) {
  var location = _ref4.location,
      title = _ref4.title,
      classes = _ref4.classes,
      handleDrawer = _ref4.handleDrawer;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_AppBar.default, {
    position: "static",
    color: "default"
  }, _react.default.createElement(_Toolbar.default, null, _react.default.createElement("img", {
    src: _ftrustee.default,
    style: {
      height: '50px'
    },
    className: "App-logo",
    alt: "logo"
  }))), _react.default.createElement(_AppBar.default, {
    position: "sticky",
    color: "primary"
  }, _react.default.createElement(_Toolbar.default, {
    disableGutters: true
  }, _react.default.createElement(_IconButton.default, {
    className: classes.menuButton,
    color: "inherit",
    onClick: handleDrawer,
    "aria-label": "open drawer"
  }, _react.default.createElement(_Menu.default, null)), _react.default.createElement(Title, {
    titile: location.state && location.state.title || title
  }))));
};

var _default = (0, _recompose.compose)(_reactRouter.withRouter, (0, _styles.withStyles)(styles, {
  withTheme: true
}))(Header);

exports.default = _default;