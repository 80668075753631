import { compose, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { filter } from 'lodash'

import { GridView } from '@ftrustee/ui'

export const QUERY = gql`
  query securities {
    securities {
      id
      name
      issueDate
      dueDate
      issuer {
        id
        name
      }
      documents {
        id
        title
        registers
      }
    }
  }
`

const withData = graphql(QUERY)

export default compose(
  withData,
  withProps(({ data }) => ({
    columns: [
      { name: 'name', title: 'OPERAÇÃO' },
      {
        name: 'issuer',
        title: 'EMISSOR',
        getCellValue: row => row.issuer && row.issuer.name
      },
      {
        name: 'documents',
        title: 'Documentos',
        getCellValue: row => {
          return row.documents.length
        }
      },
      {
        name: 'pending',
        title: 'Pendências',
        getCellValue: row => {
          let pending = 0
          row.documents.forEach(element => {
            const total = element.registers.length
            const done = filter(element.registers, { done: true }).length
            pending += total - done
          })
          return pending
        }
      },
      { name: 'id', title: ' ' }
    ],
    extensions: [
      { columnName: 'id', width: 75, align: 'center' },
      { columnName: 'documents', width: 120, align: 'center' },
      { columnName: 'pending', width: 120, align: 'center' }
    ],
    grouping: [{ columnName: 'issuer' }],
    loading: data.loading,
    rows: data.securities,
    nofab: true
  }))
)(GridView)
