"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reduxForm = require("redux-form");

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));

var _conformToMask = _interopRequireDefault(require("./conformToMask"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var isRequired = function isRequired(value) {
  return value ? undefined : 'Obrigatório';
};

var upper = function upper(value) {
  return value && value.toUpperCase();
};

var renderTextField = function renderTextField(_ref) {
  var input = _ref.input,
      _ref$meta = _ref.meta,
      touched = _ref$meta.touched,
      error = _ref$meta.error,
      helperText = _ref.helperText,
      custom = _objectWithoutProperties(_ref, ["input", "meta", "helperText"]); // console.log(`renderTextField#${input.name}`, touched, error)


  return _react.default.createElement(_TextField.default, _extends({
    fullWidth: true
  }, input, custom, {
    error: touched && !!error,
    helperText: touched && !!error && error || helperText
  }));
};

var FormField = function FormField(_ref2) {
  var xs = _ref2.xs,
      sm = _ref2.sm,
      md = _ref2.md,
      lg = _ref2.lg,
      xl = _ref2.xl,
      upperCase = _ref2.upperCase,
      props = _objectWithoutProperties(_ref2, ["xs", "sm", "md", "lg", "xl", "upperCase"]);

  var validate = [];
  var custom = {};
  if (props.required) validate.push(isRequired);
  if (upperCase) custom.normalize = upper;

  switch (props.type) {
    case 'cnpj':
      validate.push(isCNPJ);
      custom.normalize = normalizeCNPJ;
      break;

    case 'phone':
      custom.normalize = normalizePhone;
      break;

    case 'cep':
      custom.normalize = normalizeZip;
      break;

    case 'date':
      custom.InputLabelProps = {
        shrink: true
      };
      break;

    default:
  }

  return _react.default.createElement(_Grid.default, _extends({
    item: true
  }, {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    xl: xl
  }), _react.default.createElement(_reduxForm.Field, _extends({}, props, custom, {
    component: renderTextField,
    validate: validate
  })));
};

var _default = FormField;
exports.default = _default;

var isCNPJ = function isCNPJ(value) {
  var cnpj = value.replace(/[^\d]+/g, ''); // Valida a quantidade de caracteres

  if (cnpj.length !== 14) return 'Deve ter 14 dígitos'; // Elimina inválidos com todos os caracteres iguais

  if (/^(\d)\1+$/.test(cnpj)) return 'Inválido'; // Cáculo de validação

  var t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0), 10),
      d2 = parseInt(d.charAt(1), 10),
      calc = function calc(x) {
    var n = cnpj.substring(0, x),
        y = x - 7,
        value = 0,
        r = 0;

    for (var i = x; i >= 1; i--) {
      value += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }

    r = 11 - value % 11;
    return r > 9 ? 0 : r;
  };

  return calc(t) === d1 && calc(t + 1) === d2 ? undefined : 'Inválido';
};

var normalizeZip = function normalizeZip(value) {
  var mask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  var formatted = (0, _conformToMask.default)(value, mask, {
    guide: false
  });
  return formatted.conformedValue;
};

var normalizeCNPJ = function normalizeCNPJ(value) {
  var mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  var formatted = (0, _conformToMask.default)(value, mask, {
    guide: false
  });
  return formatted.conformedValue;
};

var normalizePhone = function normalizePhone(value) {
  var phoneNumberMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d|-/, /\d|-/, /\d/, /\d/, /\d/, /\d/];
  var formatted = (0, _conformToMask.default)(value, phoneNumberMask, {
    guide: false
  }); // const formatted = value.replace(/^(\d{3})(\d{3})?(\d{4})?.*/, '$1-$2-$3')
  // console.log('#normalizePhone', value, formatted)

  return formatted.conformedValue;
};