"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRouter = require("react-router");

var _reactRedux = require("react-redux");

var _recompose = require("recompose");

var _reactRouterDom = require("react-router-dom");

var _styles = require("@material-ui/core/styles");

var _Paper = _interopRequireDefault(require("@material-ui/core/Paper"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _Add = _interopRequireDefault(require("@material-ui/icons/Add"));

var _IconButton = _interopRequireDefault(require("@material-ui/core/IconButton"));

var _Edit = _interopRequireDefault(require("@material-ui/icons/Edit"));

var _CircularProgress = _interopRequireDefault(require("@material-ui/core/CircularProgress"));

var _dxReactGridMaterialUi = require("@devexpress/dx-react-grid-material-ui");

var _dxReactGrid = require("@devexpress/dx-react-grid");

var _lodash = require("lodash");

var _moment = _interopRequireDefault(require("moment"));

var _TableDetailToggleCell = _interopRequireDefault(require("./TableDetailToggleCell"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var styles = function styles(theme) {
  return {
    fab: {
      position: 'fixed',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 2
    },
    grid: {
      '& tbody tr:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04) !Important',
        cursor: 'pointer'
      }
    },
    cell: {
      width: '100%',
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit
    },
    input: {
      width: '100%'
    }
  };
};

var EditButton = function EditButton(_ref) {
  var row = _ref.row,
      value = _ref.value,
      match = _ref.match;
  var location = {
    pathname: "/".concat(match.url.split('/')[1], "/").concat(value),
    state: {
      title: row.name
    }
  };
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactRouterDom.Link, {
    to: location
  }, _react.default.createElement(_IconButton.default, {
    title: "Editar"
  }, _react.default.createElement(_Edit.default, null))));
};

var EditButtonTypeProvider = function EditButtonTypeProvider(props) {
  return _react.default.createElement(_dxReactGrid.DataTypeProvider, _extends({
    formatterComponent: (0, _reactRouter.withRouter)(EditButton)
  }, props));
};

var LoadingState = function LoadingState(_ref2) {
  var loading = _ref2.loading,
      columnCount = _ref2.columnCount;
  return _react.default.createElement("td", {
    colSpan: columnCount,
    style: {
      textAlign: 'center',
      verticalAlign: 'middle'
    }
  }, _react.default.createElement("big", null, loading ? _react.default.createElement(_CircularProgress.default, null) : _react.default.createElement("span", null, "No data")));
};

var GridView = function GridView(_ref3) {
  var history = _ref3.history,
      match = _ref3.match,
      classes = _ref3.classes,
      columns = _ref3.columns,
      rows = _ref3.rows,
      extensions = _ref3.extensions,
      bands = _ref3.bands,
      grouping = _ref3.grouping,
      loading = _ref3.loading,
      isLoading = _ref3.isLoading,
      detailComponent = _ref3.detailComponent,
      onSelect = _ref3.onSelect,
      nofab = _ref3.nofab;

  var getRowId = function getRowId(row) {
    return row.id;
  };

  isLoading(loading);
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Paper.default, {
    className: onSelect && classes.grid
  }, _react.default.createElement(_dxReactGridMaterialUi.Grid, {
    rows: rows,
    columns: columns,
    getRowId: getRowId
  }, _react.default.createElement(_dxReactGrid.SortingState, {
    defaultSorting: (0, _lodash.filter)(columns, 'sort').map(function (col) {
      return {
        columnName: col.name,
        direction: col.sort
      };
    })
  }), _react.default.createElement(_dxReactGrid.IntegratedSorting, null), _react.default.createElement(_dxReactGrid.SearchState, null), _react.default.createElement(_dxReactGrid.FilteringState, {
    columnExtensions: [{
      columnName: 'id',
      filteringEnabled: false
    }]
  }), _react.default.createElement(_dxReactGrid.RowDetailState, null), _react.default.createElement(_dxReactGrid.IntegratedFiltering, {
    columnExtensions: (0, _lodash.filter)(columns, {
      type: 'date'
    }).map(function (col) {
      return {
        columnName: col.name,
        predicate: function predicate(value, filter, row) {
          if (!filter.value || !filter.value.length) return true;
          console.log(value, filter);
          return _dxReactGrid.IntegratedFiltering.defaultPredicate((0, _moment.default)(value), _objectSpread({}, filter, {
            value: (0, _moment.default)(filter.value)
          }), row);
        }
      };
    })
  }), grouping && _react.default.createElement(_dxReactGrid.GroupingState, {
    grouping: grouping
  }), grouping && _react.default.createElement(_dxReactGrid.IntegratedGrouping, null), _react.default.createElement(_dxReactGrid.SelectionState, {
    onSelectionChange: onSelect
  }), _react.default.createElement(EditButtonTypeProvider, {
    for: ['id']
  }), _react.default.createElement(_dxReactGridMaterialUi.DragDropProvider, null), _react.default.createElement(_dxReactGrid.DataTypeProvider, {
    for: (0, _lodash.filter)(columns, {
      type: 'number'
    }).map(function (col) {
      return col.name;
    }),
    availableFilterOperations: ['equal', 'notEqual', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual']
  }), _react.default.createElement(_dxReactGrid.DataTypeProvider, {
    for: (0, _lodash.filter)(columns, {
      type: 'date'
    }).map(function (col) {
      return col.name;
    }),
    availableFilterOperations: ['contains', 'greaterThanOrEqual', 'lessThanOrEqual'],
    formatterComponent: function formatterComponent(_ref4) {
      var value = _ref4.value;
      return value && (0, _moment.default)(value).isValid() ? (0, _moment.default)(value).format('L') : value || '';
    }
  }), _react.default.createElement(_dxReactGridMaterialUi.VirtualTable, {
    height: window.innerHeight - 64,
    columnExtensions: extensions,
    noDataCellComponent: function noDataCellComponent() {
      return _react.default.createElement(LoadingState, {
        columnCount: columns.length,
        loading: loading
      });
    }
  }), _react.default.createElement(_dxReactGridMaterialUi.TableColumnReordering, {
    defaultOrder: columns.map(function (col) {
      return col.name;
    })
  }), _react.default.createElement(_dxReactGridMaterialUi.TableHeaderRow, {
    showSortingControls: true
  }), onSelect && _react.default.createElement(_dxReactGridMaterialUi.TableSelection, {
    selectByRowClick: true,
    highlightRow: true,
    showSelectionColumn: false
  }), bands && _react.default.createElement(_dxReactGridMaterialUi.TableBandHeader, {
    columnBands: bands
  }), grouping && _react.default.createElement(_dxReactGridMaterialUi.TableGroupRow, null), detailComponent && _react.default.createElement(_dxReactGridMaterialUi.TableRowDetail, {
    contentComponent: detailComponent
  }), _react.default.createElement(_dxReactGridMaterialUi.TableColumnVisibility, null), _react.default.createElement(_dxReactGridMaterialUi.Toolbar, null), _react.default.createElement(_dxReactGridMaterialUi.SearchPanel, null), _react.default.createElement(_dxReactGridMaterialUi.ColumnChooser, null))), !nofab && _react.default.createElement(_Button.default, {
    variant: "fab",
    color: "primary",
    "aria-label": "add",
    onClick: function onClick() {
      return history.push("".concat(match.url, "/new"));
    },
    className: classes.fab
  }, _react.default.createElement(_Add.default, null)));
};

GridView.defaultProps = {
  rows: [],
  nofab: false
};
GridView.TableDetailToggleCell = _TableDetailToggleCell.default;

var _default = (0, _recompose.compose)((0, _styles.withStyles)(styles), _reactRouter.withRouter, (0, _reactRedux.connect)(null, function (dispatch) {
  return {
    isLoading: function isLoading(loading) {
      return dispatch({
        type: 'APP_LOADING',
        payload: loading
      });
    }
  };
}))(GridView);

exports.default = _default;