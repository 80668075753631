"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reduxForm = require("redux-form");

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _TextField = _interopRequireDefault(require("@material-ui/core/TextField"));

var _MenuItem = _interopRequireDefault(require("@material-ui/core/MenuItem"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var required = function required(value) {
  return value ? undefined : 'Required';
};

var renderField = function renderField(_ref) {
  var input = _ref.input,
      options = _ref.options,
      _ref$meta = _ref.meta,
      touched = _ref$meta.touched,
      error = _ref$meta.error,
      placeholder = _ref.placeholder,
      custom = _objectWithoutProperties(_ref, ["input", "options", "meta", "placeholder"]); // console.log(`renderField#${input.name}`, touched, error)


  return _react.default.createElement(_TextField.default, _extends({
    fullWidth: true,
    select: true
  }, input, custom, {
    error: touched && !!error,
    SelectProps: {
      displayEmpty: true
    },
    helperText: touched && !!error && error
  }), placeholder && _react.default.createElement(_MenuItem.default, {
    value: "",
    disabled: true
  }, _react.default.createElement("span", {
    style: {
      color: 'currentColor',
      opacity: 0.5
    }
  }, placeholder)), options.map(function (option) {
    var _option$value = option.value,
        value = _option$value === void 0 ? option.toUpperCase() : _option$value,
        _option$label = option.label,
        label = _option$label === void 0 ? option : _option$label,
        props = _objectWithoutProperties(option, ["value", "label"]);

    return _react.default.createElement(_MenuItem.default, _extends({
      key: value,
      value: value
    }, props), label);
  }));
};

var SelectField = function SelectField(_ref2) {
  var xs = _ref2.xs,
      sm = _ref2.sm,
      md = _ref2.md,
      lg = _ref2.lg,
      xl = _ref2.xl,
      props = _objectWithoutProperties(_ref2, ["xs", "sm", "md", "lg", "xl"]);

  var validate = [];
  if (props.required) validate.push(required);
  return _react.default.createElement(_Grid.default, _extends({
    item: true
  }, {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    xl: xl
  }), _react.default.createElement(_reduxForm.Field, _extends({}, props, {
    component: renderField,
    validate: validate
  })));
};

SelectField.propTypes = {
  options: _propTypes.default.array.isRequired
};
SelectField.defaultProps = {
  options: []
};
var _default = SelectField;
exports.default = _default;