import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'

import { TextField, CheckboxField, SwitchField } from '@ftrustee/ui'

class CustomField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { checked: false, required: false }
    this.checkboxRef = React.createRef()
    this.switchRef = React.createRef()
  }

  componentDidMount() {
    this.setState({
      checked: this.checkboxRef.current.checked,
      required: this.switchRef.current.checked
    })
  }

  render() {
    const { name, ...props } = this.props
    const { required, checked } = this.state
    return (
      <TextField
        {...props}
        name={`${name}.value`}
        placeholder={props.label}
        disabled={!required || !checked}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CheckboxField
                color="primary"
                inputRef={this.checkboxRef}
                disabled={!required}
                name={`${name}.checked`}
                onChange={(event, value) => this.setState({ checked: value })}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <SwitchField
                inputRef={this.switchRef}
                name={`${name}.required`}
                onChange={(event, value) => this.setState({ required: value })}
              />
            </InputAdornment>
          )
        }}
      />
    )
  }
}

export default CustomField
