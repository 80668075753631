"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _app = _interopRequireDefault(require("firebase/app"));

require("firebase/auth");

require("firebase/storage");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

if (!"AIzaSyAAjlUumA16rfQsACpv2obDZR2gqW-yMXA") {
  throw new Error('REACT_APP_FIREBASE_APIKEY not found!');
}

var config = {
  apiKey: "AIzaSyAAjlUumA16rfQsACpv2obDZR2gqW-yMXA",
  authDomain: "fincshub.firebaseapp.com",
  databaseURL: "https://fincshub.firebaseio.com",
  projectId: "fincshub",
  storageBucket: "fincshub.appspot.com",
  messagingSenderId: "172344325701"
};

var app = _app.default.initializeApp(config);

var _default = _react.default.createContext(app);

exports.default = _default;