"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _reactRouterDom = require("react-router-dom");

var _reactRouter = require("react-router");

var _recompose = require("recompose");

var _Drawer = _interopRequireDefault(require("@material-ui/core/Drawer"));

var _Divider = _interopRequireDefault(require("@material-ui/core/Divider"));

var _ListItem = _interopRequireDefault(require("@material-ui/core/ListItem"));

var _List = _interopRequireDefault(require("@material-ui/core/List"));

var _ListItemIcon = _interopRequireDefault(require("@material-ui/core/ListItemIcon"));

var _ListItemText = _interopRequireDefault(require("@material-ui/core/ListItemText"));

var _Icon = _interopRequireDefault(require("@material-ui/core/Icon"));

var _PowerSettingsNew = _interopRequireDefault(require("@material-ui/icons/PowerSettingsNew"));

var _Home = _interopRequireDefault(require("@material-ui/icons/Home"));

var _Person = _interopRequireDefault(require("@material-ui/icons/Person"));

var _MonetizationOn = _interopRequireDefault(require("@material-ui/icons/MonetizationOn"));

var _LibraryBooks = _interopRequireDefault(require("@material-ui/icons/LibraryBooks"));

var _styles = require("@material-ui/core/styles");

var _theme = require("../theme");

var _FireContext = _interopRequireDefault(require("../context/FireContext"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var drawerWidth = 240;

var styles = function styles(theme) {
  return {
    paper: {
      top: '64px !important',
      position: 'sticky !important',
      width: drawerWidth,
      height: 'calc(100vh - 64px) !important',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    closed: {
      width: 60,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    listitem: {
      paddingLeft: '16px !important'
    },
    active: {
      backgroundColor: theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      },
      '& $primary, & $icon': {
        color: theme.palette.common.white
      }
    }
  };
};

var MenuItem = (0, _recompose.pure)(function (_ref) {
  var ico = _ref.ico,
      label = _ref.label,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["ico", "label", "children"]);

  return _react.default.createElement(_ListItem.default, _extends({}, props, {
    button: true,
    component: _reactRouterDom.NavLink
  }), children ? children : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ListItemIcon.default, null, _react.default.createElement(_Icon.default, null, ico)), _react.default.createElement(_ListItemText.default, {
    primary: label
  })));
});

var Menu = function Menu(_ref2) {
  var classes = _ref2.classes,
      open = _ref2.open,
      _ref2$menu = _ref2.menu,
      menu = _ref2$menu === void 0 ? [] : _ref2$menu;
  var sidetheme = (0, _styles.createMuiTheme)(_theme.dark);
  var firebase = (0, _react.useContext)(_FireContext.default);
  return _react.default.createElement(_styles.MuiThemeProvider, {
    theme: sidetheme
  }, _react.default.createElement(_Drawer.default, {
    variant: "permanent",
    classes: {
      paper: (0, _classnames.default)(classes.paper, !open && classes.closed)
    }
  }, _react.default.createElement(_List.default, {
    component: "nav"
  }, _react.default.createElement(MenuItem, {
    exact: true,
    activeClassName: classes.active,
    classes: {
      gutters: classes.listitem
    },
    to: "/"
  }, _react.default.createElement(_ListItemIcon.default, null, _react.default.createElement(_Home.default, null)), _react.default.createElement(_ListItemText.default, {
    primary: "Home"
  })), menu.map(function (item) {
    return _react.default.createElement(MenuItem, _extends({}, item, {
      activeClassName: classes.active,
      classes: {
        gutters: classes.listitem
      }
    }));
  }), _react.default.createElement(_Divider.default, null), _react.default.createElement(_ListItem.default, {
    button: true,
    classes: {
      gutters: classes.listitem
    }
  }, _react.default.createElement(_ListItemIcon.default, null, _react.default.createElement(_Person.default, null)), _react.default.createElement(_ListItemText.default, {
    primary: "Perfil"
  })), _react.default.createElement(_ListItem.default, {
    button: true,
    activeClassName: classes.active,
    classes: {
      gutters: classes.listitem
    },
    onClick: function onClick() {
      console.log(firebase);
      firebase.auth().signOut().then(function () {
        // Sign-out successful.
        window.token = null;
      }).catch(function (error) {// An error happened.
      });
    }
  }, _react.default.createElement(_ListItemIcon.default, null, _react.default.createElement(_PowerSettingsNew.default, null)), _react.default.createElement(_ListItemText.default, {
    primary: "Sair"
  })))));
};

var _default = (0, _recompose.compose)(_reactRouter.withRouter, (0, _styles.withStyles)(styles, {
  withTheme: true
}), _recompose.pure)(Menu);

exports.default = _default;