import React from 'react'
import { FormSection } from 'redux-form'
import { compose, pure } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CustomField from './CustomField'
import { AutocompleteField, FileInput, CheckboxField } from '@ftrustee/ui'

const RegisterItem = ({
  classes,
  loading,
  name,
  options,
  file,
  url,
  children,
  ...props
}) => {
  // console.log(props, url)

  return (
    <Grid container spacing={16}>
      <AutocompleteField
        sm
        name={`${name}.name`}
        label="Registro"
        options={options}
      />
      <Grid item sm={4}>
        <FileInput name={`${name}.file`} />
        <FormControlLabel
          control={
            <CheckboxField
              name={`${name}.done`}
              classes={{
                root: classes.root,
                checked: classes.checked
              }}
              onChange={(event, newValue, previousValue, name) => {
                !previousValue &&
                  !window.confirm('Confirma a finalização da pendência?') &&
                  event.preventDefault()
              }}
            />
          }
          label="PENDÊNCIA FINALIZADA"
        />
      </Grid>
      <Grid item sm={12}>
        <FormSection
          container
          spacing={16}
          component={Grid}
          name={`${name}.tracking`}>
          <CustomField sm={4} name="pdf" label="PDF" />
          <CustomField sm={4} name="copy" label="CÓPIA AUTENTICADA" />
          <CustomField sm={4} name="original" label="VIA ORIGINAL" />
        </FormSection>
      </Grid>
      <Grid item sm={12}>
        {children}
      </Grid>
    </Grid>
  )
}

const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500]
    }
  },
  checked: {}
}

export default compose(
  withStyles(styles),
  pure
)(RegisterItem)
