"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reduxForm = require("redux-form");

var _Grid = _interopRequireDefault(require("@material-ui/core/Grid"));

var _DatePicker = require("material-ui-pickers/DatePicker");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var isRequired = function isRequired(value) {
  return value ? undefined : 'Obrigatório';
};

var renderTextField = function renderTextField(_ref) {
  var input = _ref.input,
      _ref$meta = _ref.meta,
      touched = _ref$meta.touched,
      error = _ref$meta.error,
      helperText = _ref.helperText,
      custom = _objectWithoutProperties(_ref, ["input", "meta", "helperText"]); // console.log(`renderTextField#${input.name}`, touched, error)


  return _react.default.createElement(_DatePicker.InlineDatePicker, _extends({
    keyboard: true,
    fullWidth: true
  }, input, custom, {
    error: touched && !!error,
    helperText: touched && !!error && error || helperText,
    format: "DD/MM/YYYY",
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    onChange: function onChange(date) {
      return input.onChange(date.format('YYYY-MM-DD'));
    }
  }));
};

var DateField = function DateField(_ref2) {
  var xs = _ref2.xs,
      sm = _ref2.sm,
      md = _ref2.md,
      lg = _ref2.lg,
      xl = _ref2.xl,
      props = _objectWithoutProperties(_ref2, ["xs", "sm", "md", "lg", "xl"]);

  var validate = [];
  var custom = {};
  if (props.required) validate.push(isRequired);
  return _react.default.createElement(_Grid.default, _extends({
    item: true
  }, {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    xl: xl
  }), _react.default.createElement(_reduxForm.Field, _extends({}, props, custom, {
    component: renderTextField,
    validate: validate
  })));
};

var _default = DateField;
exports.default = _default;