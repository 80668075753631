import { compose, withProps, branch } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withRouter } from 'react-router'
import { omit, omitBy, remove, isNull, set } from 'lodash'
import Joi from 'joi'

import FormView from './FormView'
import { QUERY } from './DocumentListContainer'

function validate(values) {
  const schema = {
    registers: Joi.array().items({
      name: Joi.string().required(),
      tracking: Joi.object({
        copy: Joi.any(),
        original: Joi.any(),
        pdf: Joi.any()
      }).required()
    }),
    issueDate: Joi.date().less('now')
  }
  const { error, value } = Joi.validate(values, schema, {
    abortEarly: false,
    stripUnknown: true
  })
  const errors =
    error &&
    error.details.reduce((prev, curr) => {
      set(prev, curr.path, curr.message)
      return { ...prev }
    }, {})
  console.log(error && error.details, errors, value, values)
  return errors
}
const withSave = graphql(
  gql`
    mutation createDocument($data: DocumentInput!) {
      createDocument(data: $data) {
        id
        title
        reference
        inventory
        issueDate
        notes
        tracking
        registers
      }
    }
  `,
  {
    props: ({ mutate, ownProps: { match, onCancel, currentTab } }) => ({
      validate,
      onSubmit: values => {
        const data = {
          securityId: match.params.id,
          reference: currentTab,
          ...values
        }
        return mutate({ variables: { data } }).then(onCancel)
      }
    }),
    options: ({ match }) => ({
      update: (cache, { data: { createDocument } }) => {
        const { security } = cache.readQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          }
        })
        security.documents.push(createDocument)
        cache.writeQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          },
          data: {
            security
          }
        })
      }
    })
  }
)

const withData = graphql(
  gql`
    query document($id: ID!) {
      document(id: $id) {
        id
        reference
        inventory
        title
        issueDate
        notes
        tracking
        registers
        security {
          id
          name
          type
        }
      }
    }
  `,
  {
    options: ({ documentId }) => ({
      variables: {
        id: documentId
      }
    }),
    props: ({ data }) => {
      const props = {
        loading: data.loading
      }
      if (!data.loading) {
        const { group, ...initialValues } = omit(
          omitBy(data.document, isNull),
          '__typename'
        )
        if (group) initialValues.groupId = group.id
        props.initialValues = initialValues
      }
      return props
    }
  }
)

const withUpade = graphql(
  gql`
    mutation updateDocument($id: ID!, $data: DocumentInput!) {
      updateDocument(id: $id, data: $data) {
        id
        reference
        inventory
        title
        issueDate
        notes
        tracking
        registers
        security {
          id
          name
          type
        }
      }
    }
  `,
  {
    props: ({ mutate, ownProps: { onCancel } }) => ({
      onSubmit: values => {
        const { id, ...data } = values
        return mutate({ variables: { id, data } }).then(onCancel)
      }
    })
  }
)

const withDelete = graphql(
  gql`
    mutation deleteDocument($id: ID!) {
      deleteDocument(id: $id) {
        id
      }
    }
  `,
  {
    props: ({ mutate, ownProps: { onCancel } }) => ({
      onDelete: id => mutate({ variables: { id } }).then(onCancel)
    }),
    options: ({ match }) => ({
      update: (cache, { data: { deleteDocument } }) => {
        const { security } = cache.readQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          }
        })
        remove(security.documents, deleteDocument)
        cache.writeQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          },
          data: { security }
        })
      }
    })
  }
)

export default compose(
  withRouter,
  branch(
    ({ documentId }) => !!documentId,
    compose(
      withData,
      withUpade,
      withDelete
    ),
    withSave
  ),
  withProps(({ type, currentTab }) => ({
    options: {
      registers: [
        'JUCEPAR',
        'RTD - São Paulo',
        'RTD - Rio de Janeiro',
        'RTD - Sorocaba'
      ].map(str => ({ label: str, value: str })),
      inventories: inventories[type][currentTab]
    }
  }))
)(FormView)

const inventories = {
  NPC: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['NOTA COMERCIAL'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'PRESTAÇÃO DE SERVIÇOS',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES',
      'DIQ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS'
    ]
  },
  DEB: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['DEBENTURE'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS'
    ]
  },
  CRI: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: [
      'CRI',
      'LASTRO CCI',
      'EMISSÃO CCI',
      'CESSÃO DE DIREITOS',
      'RECOMPRA CCI',
      'MATRICULAS DE IMOVEIS',
      'OUTROS'
    ],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS',
      'FUNDOS',
      'AQUISIÇÃO E RECOMPRA',
      'CÁLCULOS',
      'RELATÓRIOS'
    ]
  },
  CRA: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['CRA', 'LASTRO CRA', 'CESSÃO DE DIREITOS', 'OUTROS'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'COMPARTILHAMENTO DE GARANTIA'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS',
      'FUNDOS',
      'AQUISIÇÃO E RECOMPRA',
      'CÁLCULOS',
      'RELATÓRIOS'
    ]
  },
  LFS: {
    ata: ['EMISSORA', 'GARANTIDORA', 'ASSEMBLEIAS'],
    produto: ['LETRA FINANCEIRA'],
    garantias: [
      'PENHOR',
      'QUOTAS, AÇÕES E MOVEIS',
      'IMOVEIS',
      'LIVRO DE REGISTROS',
      'CONTRATO SOCIAL',
      'EMBARCAÇÕES',
      'DIREITOS CREDITORIOS',
      'CONTRATO DE BANCO DEPOSITÁRIO',
      'FIANÇA/AVAL',
      'HIPOTECA',
      'GARANTIAS ESTRANGEIRAS',
      'COMPROMISSOS DE CAPITALIZAÇÃO',
      'PRESTAÇÃO DE SERVIÇOS'
    ],
    importante: [
      'PROCURAÇÕES',
      'SEGUROS',
      'COMPLETION FINANCEIRO',
      'COMPLETION FISICO',
      'CONVOLAÇÃO',
      'CONTROLES ',
      'AÇÕES JUDICIAIS',
      'OBRIGAÇÕES EXTRAORDINARIAS'
    ]
  }
}
