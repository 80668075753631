import { compose, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { GridView } from '@ftrustee/ui'

export const QUERY = gql`
  query securities {
    securities {
      id
      cod
      name
      type
      status
      notes
      issuer {
        id
        name
      }
    }
  }
`

const withData = graphql(QUERY)

export default compose(
  withData,
  withProps(({ data }) => ({
    columns: [
      { name: 'cod', title: 'CÓDIGO' },
      { name: 'name', title: 'OPERAÇÃO' },
      {
        name: 'issuer',
        title: 'EMISSOR',
        sort: 'asc',
        getCellValue: row => row.issuer && row.issuer.name
      },
      { name: 'id', title: ' ' }
    ],
    extensions: [
      { columnName: 'cod', width: 200, align: 'left' },
      { columnName: 'id', width: 75, align: 'right' }
    ],
    loading: data.loading,
    rows: data.securities
  }))
)(GridView)
